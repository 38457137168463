$( document ).ready(function() {

  $(".js-phone").mask("+7(999)999-99-99",{placeholder:"+7(___)___-__-__"});


  $(window).scroll(function () {
    if ($(window).scrollTop() > 300) {
        $(".js-up").addClass('is-active');
    } else {
        $(".js-up").removeClass('is-active');
    }
  });

  $(".js-mobile-menu-link").on('click', function (e) {
    $(this).parents("li").toggleClass("is-active");
    $(this).next(".js-mobile-menu-dropmenu").slideToggle(300 , function() {});

    return false;
  });

  $(".js-up").on('click', function (e) {
      e.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, '300');
  });

  /*** INDEX ANIMATION ***/
  $(window).scroll(function () {
    let scrollTop = $(window).scrollTop();

    $(".js-section-img").css({'transform': 'translateY(' + (scrollTop / 5 * (-1)) + 'px)'});
    $(".js-banner-img-second").css({'transform': 'translateY(' + (scrollTop / 5 * (-1)) + 'px)'});
    $(".js-banner-img-first").css({'transform': 'translateY(' + (scrollTop / 5 * (-1)) + 'px)'});
    $(".js-main-banner-play").css({'transform': 'translateY(' + (scrollTop / 4 * (-1)) + 'px)'});
  });

 
  if ($(window).width() > 960) {
    var lastScrollTop = 0;
    $(window).scroll(function () {
        var scrollTop = $(window).scrollTop();

        $('.js-section-main').each(function (index, value) { 
          let position = $(this).position().top;

          if (scrollTop > $(".object").offset().top - $(window).height()/1.5) {
            $(".object__item").addClass("is-active");
          }

          $(".js-big-menu-link").removeClass("is-active");
          $(".js-big-menu-link[data-block="+$(this).data("block")+"]").addClass("is-active");

          if (scrollTop > lastScrollTop){
            if ((scrollTop > (position - $(this).height()*0.8)) && (scrollTop < (position + $(this).height()) )) {
              $(".js-big-menu-img[data-block="+$(this).data("block")+"]").addClass("is-active");
              
            }
          } else {
            if ((scrollTop > (position - $(this).height()*0.8)) && (scrollTop < (position + $(this).height()) )) {
              $(".js-big-menu-img[data-block="+($(value).next().data("block"))+"]").removeClass("is-active");
              $(".js-big-menu-link").removeClass("is-active");
              $(".js-big-menu-link[data-block="+$(this).data("block")+"]").addClass("is-active");
            }
          }
        });

        lastScrollTop = scrollTop;

    });
  }

  /*** END INDEX ANIMATION ***/

  /*** POPUP ***/
  $(".js-popup-close").on("click", function () {
    var popup = $(this).parents(".js-popup");

    $(popup).removeClass("is-active");
    $("body").removeClass("is-hidden");
  });

  $(document).on("click", function (e) {
    var popup = $(".js-popup");
    if (popup.is(e.target)) {
      $(popup).removeClass("is-active");
      $("body").removeClass("is-hidden");
    }
  });

  $(".js-popup-form-close").on("click", function (e) {
    $(".js-popup-form").removeClass("is-active");
  });

  $(".js-popup-form-open").on("click", function (e) {
    var popup = $(this).parents(".js-popup");

    popup.find(".js-popup-form").addClass("is-active");
    return false;
  });

  $(".js-popup-open").on("click", function () {
    var name = $(this).data("popup");

    $(".js-popup[data-popup='" + name + "']").addClass("is-active");
    $("body").addClass("is-hidden");

    return false;
  });
  /*** POPUP END ***/


  /*** TAB ****/

  $(".js-tab-link").on("click", function () {
      var tabNumber = $(this).data("tab"),
          parents = $(this).parents(".js-tab");

      $(parents).find(".js-tab-link").removeClass("is-active");
      $(this).addClass("is-active");

      $(parents).find(".js-tab-block").removeClass("is-active");
      $(parents).find(".js-tab-block[data-tab=" + tabNumber + "]").addClass("is-active");
  });

  
  /***  END TAB ****/
  $(".js-sticky").stick_in_parent({
    offset_top: 0
  });


  if ($(window).width() < 1140) {
    $(".js-video-plit").slick({
      variableWidth: true,
      arrows: false,
      infinite: false
    });
  }

  $(".js-video").slick({
    slidesToShow: 3,
    infinite: false,
    arrows: true,
    prevArrow: '<button type="button" class="slick-prev">\
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <path d="M15.3888 1.16797L1.49609 9.18891L15.3888 17.2098" stroke-width="0.712445"/>\
    </svg>\
        </button>',
    nextArrow: '<button type="button" class="slick-next">\
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <path d="M0.24405 1.16797L14.1367 9.18891L0.24405 17.2098"  stroke-width="0.712445"/>\
    </svg>\
    </button>',
  
    responsive: [
      {
        breakpoint: 1140,
        settings: {
            variableWidth: true,
        }
      }
    ]
  });

  $(".js-doc-slider").slick({
    slidesToShow: 4,
    infinite: false,
    arrows: true,
    prevArrow: '<button type="button" class="slick-prev">\
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <path d="M15.3888 1.16797L1.49609 9.18891L15.3888 17.2098" stroke-width="0.712445"/>\
    </svg>\
        </button>',
    nextArrow: '<button type="button" class="slick-next">\
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <path d="M0.24405 1.16797L14.1367 9.18891L0.24405 17.2098"  stroke-width="0.712445"/>\
    </svg>\
    </button>',
  
    responsive: [
      {
        breakpoint: 960,
        settings: {
            variableWidth: true,
            arrows: false
        }
      }
    ]
  });

  $(".js-project-small-slider").slick({
    slidesToShow: 4,
    variableWidth: true,
    arrows: true,
    prevArrow: '<button type="button" class="slick-prev">\
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <path d="M15.3888 1.16797L1.49609 9.18891L15.3888 17.2098" stroke-width="0.712445"/>\
    </svg>\
        </button>',
    nextArrow: '<button type="button" class="slick-next">\
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <path d="M0.24405 1.16797L14.1367 9.18891L0.24405 17.2098"  stroke-width="0.712445"/>\
    </svg>\
    </button>',
  
    responsive: [
      {
        breakpoint: 1100,
        settings: {
            
        }
      }
    ]
  });


  $(".js-btn-mobile-catalog").on("click", function () {
    $(this).toggleClass("is-active");
    $(".js-mobile-catalog").toggleClass("is-active");
  });

  $(".js-close-mobile-catalog").on("click", function () {
    $(".js-btn-mobile-catalog").toggleClass("is-active");
    $(".js-mobile-catalog").toggleClass("is-active");
  });

  
  $(".js-hamburger").on("click" , function(){
    $(".js-mobile-menu").addClass("is-open");
    $("body").addClass("hidden");
  });

  $(".js-mobile-menu-close").on("click" , function(){
    $(".js-mobile-menu").removeClass("is-open");
    $("body").removeClass("hidden");
  })


  $(window).scroll(function () {
    var scrollTop = $(window).scrollTop();

    if (scrollTop > 0) {
      $(".js-btn-mobile-catalog").removeClass("is-active");
      $(".js-mobile-catalog").removeClass("is-active");
    }
  });

  $(".js-submit").on("click" , function(){
    $(".js-form-ok").addClass("is-active")
    $(this).parents(".js-section-form").find(".container").addClass("is-hidden");
    setTimeout(function () {
      $(".js-form-ok").removeClass("is-active");
      $(this).parents(".js-section-form").find(".container").removeClass("is-hidden");
    }, 3000);
    return false;
  });

  $(".js-form-ok-close").on("click" , function(){
    $(".js-form-ok").removeClass("is-active")
    $(".js-section-form").find(".container").removeClass("is-hidden");
  });

  $(".js-select").styler();







});